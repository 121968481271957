
.root {
    width: 100%;

    &__content {
        width: 80rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        overflow-y: hidden;

        .status-code {
            font-size: 10rem;
            font-weight: bold;
        }

        .description {
            font-size: 5rem;
            text-align: center;
        }
    }
}
